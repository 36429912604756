<template>
  <div class="end--container">
    <h1>GOOD WORK!</h1>

    <div class="text--container">
      <h2>You are on your way to saving energy in your home!</h2>            

      <h2>Visit www.ComEd.com/MyAccount with a parent and learn how you can save energy together as a family!</h2> 
    </div>

    <div class="image--container">
      <img src = "../assets/images/UI/icons/BrightLightGlobe.png">
    </div>

    <button @click="resetQuiz">CONTINUE</button>
  </div>
</template>
  
  <script>
  
  import { store } from '../store.js'
  export default {
    name: 'EndPage',
    data() {
      return {
        store
      }
    },
    components: {},
    methods: {
      resetQuiz(){
        window.location.reload()
      }
    }
  }
  </script>
  
  <style scoped>
.end--container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--Pantone2745__dark-blue);
    color: #fff;
}

.text--container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%
}

.image--container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

img {
  width: 10rem;
}

h1 {
  font-size: var(--font-size-six);
  font-weight: 600;
  text-transform: uppercase;
  font-family: "prototype";
  margin-bottom: 2rem;
}

h2 {
  font-size: var(--font-size-four);
  font-weight: 600;
  font-family: "prototype";
  margin-bottom: 2rem;
}

button {
  background-color: #fff;
  color: var(--Pantone2745__dark-blue);
  margin-top: 2rem;;
}
</style>
  