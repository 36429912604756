const quizQuestions = {
    "group" : 
        [{"one" :
            [
            
            {
                "number" : 0,
                "text" : [
                    "No electricity is used when I'm flipped to OFF.",
                    "I'm right at your fingertips to give you light."
                ],
                "hint" : "I'm on the wall. Flip me to ON when a room is dark.",
                "device": [0],
                "answer_pool":[5,0,2],
                "correct":["Good work!",
                "Saving energy is as easy as turning the lights off when you leave a room."],
                "incorrect":["Oops!", "The answer is the light switch. It is easy to save electricity by switching off the lights when you leave a room."],
                "question_audio":"St 4-light switch-question.wav",
                "hint_audio":"St 4-light switch-hint.wav",
                "correct_audio":"St 4-light switch-correct.wav",
                "incorrect_audio":"St 4-light switch-incorrect.wav"
            },

            {
                "number" : 1,
                "text" : [
                    "I run day and night to keep my temperatures low",
                    "Leave me open and I'll waste energy!"
                ],
                "hint" : "Brrrr! It's cold in here! Some things I chill and others I freeze.",
                "device": [1],
                "answer_pool":[9,1,4],
                "correct":[
                    "Right!",
                    "The refrigerator uses a lot of electricity to keep food cold. Leaving the door open lets the cold air out. Then more electricity is needed to cool down the inside. Keep the door shut to save energy!"
                ],
                "incorrect":[
                    "Oops!", 
                    "The refrigerator uses a lot of electricity to keep food cold. Leaving the door open lets the cold air out. Then more electricity is needed to cool down the inside. Keep the door shut to save energy!"
                ],
                "question_audio":"St 4-refrigerator-question.wav",
                "hint_audio":"St 4-refrigerator-hint.wav",
                "correct_audio":"St 4-refrigerator-correct.wav",
                "incorrect_audio":"St 4-refrigerator-incorrect.wav"
            },

            {
                "number" : 2,
                "text":[ 
                    "I'm all fun and games, but when you turn me off, I still use electricity.",
                    "Plug me into a power strip and I can save electricity!"
                ],
                "hint": "I have controllers and connect to a TV.",
                "device": [2],
                "answer_pool" : [7,0,2],
                "correct":[
                    "Yes!",
                    "A game console uses a lot of electricity, even if it is off. Using a power strip to completely shut off a game when you are done saves electricity!"
                ],
                "incorrect":[
                    "Oops!",
                    "The right choice is the game console. It uses a lot of electricity, even if it is off. Using a power strip to completely shut off a game when you are done saves electricity!"
                ],
                "question_audio":"St 4-game console-question.wav",
                "hint_audio":"St 4-game console-hint.wav",
                "correct_audio":"St 4-game console-correct.wav",
                "incorrect_audio":"St 4-game console-incorrect.wav"
            },

            {
                "number" : 3,
                "text":[ 
                    "When our batteries are low, you plug us in to charge. But if you leave our cords plugged in, we still use electricity."
                ],
                "hint": "We can make calls and surf the internet!",
                "device": [3],
                "answer_pool" : [5,3,9],
                "correct":[
                    "Awesome!",
                    "Always remove charging plugs from outlets after charging cell phones and tablets. Even plugged in, they use small amounts of electricity."
                ],
                "incorrect":[
                    "Oops!",
                    "The correct choice is the cell phone and tablet. Always remove charging plugs from outlets after charging cell phones and tablets. Even plugged in they use small amounts of electricity."
                ],
                "question_audio":"St 4-phone-tablet-question.wav",
                "hint_audio":"St 4-phone-tablet-hint.wav",
                "correct_audio":"St 4-phone-tablet-correct.wav",
                "incorrect_audio":"St 4-phone-tablet-incorrect.wav"
            },
            
            {
                "number" : 4,
                "text":[ 
                    "I light up your life right where you need it. But be smart and put a bulb in me that uses less electricity."
                ],
                "hint": "I often sit on a desk to help you see your work.",
                "device": [4],
                "answer_pool" : [9,6,4],
                "correct":[
                    "Correct!",
                    "Using newer bulbs (LEDs and CFLs) in a lamp saves energy and gives off less heat!"
                ],
                "incorrect":[
                    "Oops!",
                    "The desk lamp is the correct choice. Using newer bulbs (LEDs and CFLs) in a lamp saves energy and gives off less heat!"
                ],
                "question_audio":"St 4-desk lamp-question.wav",
                "hint_audio":"St 4-desk lamp-hint.wav",
                "correct_audio":"St 4-desk lamp-correct.wav",
                "incorrect_audio":"St 4-desk lamp-incorrect.wav"
            },
            
            {
                "number" : 5,
                "text":[ 
                    "We are great at cleaning. But when I do my job, I use cold water to save energy and money."
                ],
                "hint": "We're a team of two - one of us washes and the other dries.",
                "device": [5,6],
                "answer_pool" : [6,10,5],
                "correct":[
                    "Yes!",
                    "A clothes washer and dryer keep our clothes clean. Washing with cold water saves energy."
                ],
                "incorrect":[
                    "Oops!",
                    "The answer is clothes washer and dryer. These keep our clothes clean. Washing with cold water saves energy."
                ],
                "question_audio":"St 4-washer dryer-question.wav",
                "hint_audio":"St 4-washer dryer-hint.wav",
                "correct_audio":"St 4-washer dryer-correct.wav",
                "incorrect_audio":"St 4-washer dryer-incorrect.wav"
            },

            {
                "number" : 6,
                "text":[ 
                    "Make sure I'm full when you use me.",
                    "I can save you money if you load me completely."
                ],
                "hint": "Time to do the dishes!",
                "device": [7],
                "answer_pool" : [9,10,7],
                "correct":[
                    "Correct!",
                    "The dishwasher cleans dirty dishes. It is a better use of energy to use the dishwasher when it is full rather than just washing a few dishes at a time."
                ],
                "incorrect":[
                    "Oops!",
                    "The correct choice is the dishwasher. A dishwasher cleans dirty dishes. It is a better use of energy to use the dishwasher when it is full rather than washing a few dishes at a time."
                ],
                "question_audio":"St 4-dishwasher-question.wav",
                "hint_audio":"St 4-dishwasher-hint.wav",
                "correct_audio":"St 4-dishwasher-correct.wav",
                "incorrect_audio":"St 4-dishwasher-incorrect.wav"
            },

            {
                "number" : 7,
                "text":[ 
                    "Letting me run all of the time does not compute.",
                    "Turning me off at the end of the day is a smarter move."
                ],
                "hint": "Use my mouse and click to search the internet.",
                "device": [9],
                "answer_pool" : [1,6,9],
                "correct":[
                    "Yes!",
                    "Turning off the computer when you are done using it saves energy."
                ],
                "incorrect":[
                    "Oops!",
                    "The right choice is the computer. Turning off a computer when you are done using it saves energy."
                ],
                "question_audio":"St 4-computer-question.wav",
                "hint_audio":"St 4-computer-hint.wav",
                "correct_audio":"St 4-computer-correct.wav",
                "incorrect_audio":"St 4-computer-incorrect.wav"
            },

            {
                "number" : 8,
                "text":[ 
                    "This controls the temperature in your home."
                ],
                "hint": "I'm on the wall but not a light switch.",
                "device": [10],
                "answer_pool" : [7,1,10],
                "correct":[
                    "Yes!",
                    'The thermostat controls how hot or cold the rooms feel in your house. Some thermostats are "smart," like the Nest thermostat, and can help you save energy!'
                ],
                "incorrect":[
                    "Oops!",
                    'The answer is the smart thermostat. A thermostat controls how hot or cold the rooms feel in your house. Some thermostats are "smart," like the Nest thermostat, and can help you save energy!'
                ],
                "question_audio":"St 4-thermostat-question.wav",
                "hint_audio":"St 4-thermostat-hint.wav",
                "correct_audio":"St 4-thermostat-correct.wav",
                "incorrect_audio":"St 4-thermostat-incorrect.wav"
            },

            {
                "number" : 9,
                "text":[ 
                    "What uses the LEAST energy in this typical home?"
                ],
                "hint": "When the batteries are low on these devices, you need to charge them up!",
                "device": [3],
                "answer_pool" : [2,5,3],
                "correct":[
                    "Correct!",
                    'The cell phone and tablet chargers.'
                ],
                "incorrect":[
                    "Oops!",
                    'The correct choice is the cell phone and tablet chargers.'
                ],
                "question_audio":"St 4-charging station-question.wav",
                "hint_audio":"St 4-charging station-hint.wav",
                "correct_audio":"St 4-charging station-correct.wav",
                "incorrect_audio":"St 4-charging station-incorrect.wav"
            },

            {
                "number" : 10,
                "text":[ 
                    "On average, which costs the most to use each month, the game console, the dishwasher, or the desk lamp?"
                ],
                "hint": "The correct choice uses controllers.",
                "device": [2],
                "answer_pool" : [2,7,4],
                "correct":[
                    "Yes!",
                    'The game console. Of course it depends on how much the game console is used, but compared to the other items the game console costs the most.'
                ],
                "incorrect":[
                    "Oops!",
                    'The correct choice is the game console. Of course it depends on how much the game console is used, but compared to the other items the game console costs the most.'
                ],
                "question_audio":"St 4-Question 13-question.wav",
                "hint_audio":"St 4-Question 13-hint.wav",
                "correct_audio":"St 4-Question 13-correct.wav",
                "incorrect_audio":"St 4-Question 13-incorrect.wav"
            },

            {
                "number" : 11,
                "text":[ 
                    "Find the device you think can talk to the smart meter directly and tell you how much energy is being used."
                ],
                "hint": "A computer is nice, but this device is built for the job.",
                "device": [11],
                "answer_pool" : [11,6,2],
                "correct":[
                    "Right!",
                    "The EMU (Energy Monitoring Unit) is one of many devices that can be connected to talk with your home's smart meter to let you know your energy use in real-time!"
                ],
                "incorrect":[
                    "Oops!",
                    "The correct answer is the EMU (Energy Monitoring Unit). It is one of many devices that can be connected to talk with your home's smart meter to let you know your energy use in real-time!"
                ],
                "question_audio":"St 4-EMU-question.wav",
                "hint_audio":"St 4-EMU-hint.wav",
                "correct_audio":"St 4-EMU-correct.wav",
                "incorrect_audio":"St 4-EMU-incorrect.wav"
            },

            {
                "number" : 12,
                "text":[ 
                    "Which appliance uses MORE energy, the clothes washer or the clothes dryer?"
                ],
                "hint": "The sun and wind can do this appliance's job.",
                "device": [6],
                "answer_pool" : [5,6],
                "correct":[
                    "Right!",
                    "The clothes dryer uses more energy than the clothes washer."
                ],
                "incorrect":[
                    "Oops!",
                    "The clothes dryer is the answer."
                ],
                "question_audio":"St 4-Question 15-question.wav",
                "hint_audio":"St 4-Question 15-hint.wav",
                "correct_audio":"St 4-Question 15-correct.wav",
                "incorrect_audio":"St 4-Question 15-incorrect.wav"
            },
        
        ] 
        },
        {
            "two" : [
                {
                    "number" : 0,
                    "text" : [
                        "A very easy way to save energy is to use this when you leave a room."
                    ],
                    "hint" : "This is usually located on the wall by a doorway.",
                    "device": [0],
                    "answer_pool":[5,0,2,3,1,6],
                    "correct":[
                        "Excellent.",
                        "Turning off the lights when you leave a room can easily save energy."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct choice is the light switch. Turning off the lights when you leave a room will save energy."
                    ],
                    "question_audio":"",
                    "hint_audio":"",
                    "correct_audio":"",
                    "incorrect_audio":""
                },

                {
                    "number" : 1,
                    "text" : [
                        "This uses a lot of electricity to keep the inside temperature at 40 degrees Fahrenheit or below."
                    ],
                    "hint" : "Watch out - leave its door open and you'll waste energy.",
                    "device": [1],
                    "answer_pool":[9,1,4,6,3,11],
                    "correct":[
                        "Yes.",
                        "Make sure you shut the refrigerator door completely to keep its cool air from escaping. But watch out, refrigerators and freezers that are set too cold use more energy and waste electricity."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct choice is the refrigerator. Shut the refrigerator door completely to keep its cool air from escaping. But watch out, refrigerators and freezers that are set too cold use more electricity and waste energy."
                    ],
                    "question_audio":"",
                    "hint_audio":"",
                    "correct_audio":"",
                    "incorrect_audio":""
                },

                {
                    "number" : 2,
                    "text" : [
                        "This popular item is a lot of fun. But playing with it uses a lot of electricity. Power it off completely to save electricity."
                    ],
                    "hint" : "Game on! This electronic box connects to a TV or monitor.",
                    "device": [2],
                    "answer_pool":[7,0,2,11,1,3],
                    "correct":[
                        "Right on!",
                        "The game console uses electricity even in standby mode or powered-off without the use of a power strip. Plugging the device into a power strip and turning it off when you are done will prevent electricity from being wasted."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct answer is the game console. The game console uses electricity even in standby mode or powered-off without the use of a power strip. Plugging the device into a power strip and turning it off when you are done will prevent electricity from being wasted."
                    ],
                    "question_audio":"",
                    "hint_audio":"",
                    "correct_audio":"",
                    "incorrect_audio":""
                },

                {
                    "number" : 3,
                    "text" : [
                        "People use these items every day to communicate or find information.",
                        "Powering them up doesn't take very long, but leave their charging cords in outlets and you could be wasting energy."
                    ],
                    "hint" : "Need a hint? Phone a friend!",
                    "device": [3],
                    "answer_pool":[5,3,9,11,6,1],
                    "correct":[
                        "Perfect!",
                        "Cell phone and tablet batteries recharge and store energy. Leaving their charging cords in outlets wastes small amounts of electricity that can add up over time. Always unplug your charging devices from outlets when finished. Or use a power strip, and turn the strip off, to prevent wasting energy."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the cell phone and tablet. Cell phone and tablet batteries recharge and store energy. Leaving their charging cords in outlets when they are done wastes small amounts of electricity that can add up over time. Always unplug your charging devices from outlets when finished. Or use a power strip, and turn the strip off, to prevent wasting energy."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 4,
                    "text" : [
                        "Saving energy is easy to see if you know where to look. At night, this device helps you read a good book."
                    ],
                    "hint" : "Turn some light on the subject!",
                    "device": [4],
                    "answer_pool":[9,6,4,1,7,10],
                    "correct":[
                        "Alright!",
                        "We use lighting every day. Using LED or CFL bulbs in lamps not only cost less to use, but they give off much less heat and last years longer than incandescent bulbs."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The right choice is the desk lamp. Using LED or CFL bulbs in lamps not only cost less to use, but they give off much less heat and last years longer than incandescent bulbs."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 5,
                    "text" : [
                        "These appliances work together to remove dirt and stains. You can choose the temperature when using either of these machines.",
                        "This machine saves money by using cold water."
                    ],
                    "hint" : "Dirty clothes are better in here than on your bedroom floor.",
                    "device": [5,6],
                    "answer_pool":[6,10,5,1,9,11],
                    "correct":[
                        "Yes.",
                        "Clothes washers and dryers are great appliances but they can use a lot of energy! Save energy by choosing cold water when washing clothes."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the clothes washer and dryer. Washers and dryers are great appliances but they can use a lot of energy! Save energy by choosing cold water when washing clothes."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 6,
                    "text" : [
                        "If you don't want to wash them by hand, this will clean your dirty utensils and more!"
                    ],
                    "hint" : "This machine is very helpful after a big meal.",
                    "device": [7],
                    "answer_pool":[2,6,1,9,10,7],
                    "correct":[
                        "Good work!",
                        "A dishwasher is ideal for big jobs. But running a dishwasher half empty wastes energy and water unnecessarily."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the dishwasher. A dishwasher is ideal for big jobs. But running a dishwasher half empty wastes energy and water unnecessarily."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 7,
                    "text" : [
                        "Letting me run all of the time does not compute. Turning me off at the end of the day is a smarter move."
                    ],
                    "hint" : "You use my mouse to click where you want to go on the internet.",
                    "device": [9],
                    "answer_pool":[10,11,1,6,9,5],
                    "correct":[
                        "Yes!",
                        "A computer is something we often leave on all of the time. Save energy by powering down your computer at the end of the day when you are done using it."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct answer is the computer. Computers are something we often leave on all of the time. Save energy by powering down your computer at the end of the day when you are done using it."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 8,
                    "text" : [
                        'This device controls the temperature for your home. Some are so "smart" they can change the temperature by themselves!'
                    ],
                    "hint" : "It's usually on the wall but is not a light switch.",
                    "device": [10],
                    "answer_pool":[7,11,1,10,5,6],
                    "correct":[
                        "Yes!",
                        'The smart thermostat controls the temperature in your home by telling the furnace or air conditioning when to run and how hot or cold you would like rooms to feel. Some thermostats are "smart," like the Nest, and can help you save heating and cooling energy automatically!'
                    ],
                    "incorrect":[
                        "Oops!", 
                        'The correct answer is the smart thermostat. A thermostat controls the temperature in your home by telling the furnace or air conditioning when to run and how hot or cold you would like rooms to feel. Some thermostats are "smart," like the Nest, and can help you save heating and cooling energy automatically!'
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 9,
                    "text" : [
                        'Which device allows you to control what uses the most energy in this typical home?'
                    ],
                    "hint" : "How warm or cold are you?",
                    "device": [10],
                    "answer_pool":[7,1,10,5,6,2],
                    "correct":[
                        "Yes!",
                        "The smart thermostat, like the Nest, controls the temperature in your home. It can be used to save energy by automatically turning down the heat when you aren't at home."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the smart thermostat, like the Nest. The thermostat controls the temperature in your home. It can be used to save energy by automatically turning down the heat when you aren't at home."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 10,
                    "text" : [
                        'What uses the LEAST energy in this typical home?'
                    ],
                    "hint" : "When the batteries are low on these devices, you need to charge them up!",
                    "device": [3],
                    "answer_pool":[4,9,2,1,3,0],
                    "correct":[
                        "Correct.",
                        "The cell phone and tablet chargers."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct choice is the cell phone and tablet chargers."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 11,
                    "text" : [
                        'On average, which costs the most to use each month, the game console, the dishwasher, or the desk lamp?'
                    ],
                    "hint" : "The correct choice uses controllers.",
                    "device": [2],
                    "answer_pool":[2,7,4],
                    "correct":[
                        "Yes.",
                        "The game console. Of course it depends on how much the game console is used, but the average monthly cost to use a game console is about $2.77, a dishwasher costs about $1.73, and a desk lamp, depending on the bulb costs anywhere from $0.17 to $0.95 a month."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the game console. Of course it depends on how much the game console is used, but the average monthly cost to use a game console is about $2.77, a dishwasher costs about $1.73, and a desk lamp, depending on the bulb costs anywhere from $0.17 to $0.95 a month."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 12,
                    "text" : [
                        'Find the device you think can talk to your smart meter directly and tell you how much energy is being used.'
                    ],
                    "hint" : "A computer is nice, but this device is built for the job.",
                    "device": [11],
                    "answer_pool":[1,11,7,6,2,5],
                    "correct":[
                        "Right!",
                        "The EMU (Energy Monitoring Unit) is one of many devices that can be connected to talk with your home's smart meter to let you know your energy use in real-time!"
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the EMU (Energy Monitoring Unit). It is one of many devices that can be connected to talk with your home's smart meter to let you know your energy use in real-time!"
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },

                {
                    "number" : 13,
                    "text" : [
                        'Which appliance uses MORE energy, the clothes washer or the dryer?'
                    ],
                    "hint" : "The sun and wind can do this appliance's job.",
                    "device": [6],
                    "answer_pool":[5,6],
                    "correct":[
                        "Right!",
                        "The clothes dryer uses more energy than the washer. To run an electric clothes dryer, it costs about $92 per year, on average."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The clothes dryer is the answer. To run an electric clothes dryer, it costs about $92 per year, on average."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                }
            ]
        },
        {
            "three":[
                {
                    "number" : 0,
                    "text" : [
                        'Saving energy is right at your fingertips whenever you leave a room.'
                    ],
                    "hint" : "Can't see? Flip the lights on.",
                    "device": [0],
                    "answer_pool":[5,0,2,3,1,6],
                    "correct":[
                        "Correct.",
                        "Simply turning off the lights when you leave a room is an easy but very effective way to save energy."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the light switch. Simply turning off the lights when you leave a room is an easy but very effective way to save energy."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 1,
                    "text" : [
                        'This runs during the day and at night - all year round, but you can make sure it is running efficiently.'
                    ],
                    "hint" : "Ice crystals in your milk mean it's set way too cold!",
                    "device": [1],
                    "answer_pool":[9,1,4,6,3,11],
                    "correct":[
                        "Correct!",
                        "Refrigerators should be set to 35 to 38 degrees. Any colder and you are wasting energy. And if you leave the door open it has to work even harder."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct answer is the refrigerator. Refrigerators should be set to 35 to 38 degrees. Any colder and you are wasting energy. And if you leave the door open it has to work even harder."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 2,
                    "text" : [
                        "This device is good fun. But do you know if you've really turned this entertaining item off after use?"
                    ],
                    "hint" : "Game on. Grab a controller and play away.",
                    "device": [2],
                    "answer_pool":[7,0,2,11,1,3],
                    "correct":[
                        "Correct.",
                        "The game console uses a lot of electricity, even if in standby mode. Plugging-in a game console to a power strip, and turning the strip off, can truly power it down when not in use and add savings to your household's electric bill."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the game console. Plugging-in a game console to a power strip, and turning the strip off, can truly power it down when not in use and add some savings to your household's electric bill."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 3,
                    "text" : [
                        "Just like you, these get tired and need to recharge after a long day.",
                        "However, leave their charging cords in and you are wasting small amounts of electricity that can add up over time."
                    ],
                    "hint" : "Need help? Phone a friend.",
                    "device": [3],
                    "answer_pool":[5,3,9,11,6,1],
                    "correct":[
                        "Correct.",
                        "Cell phones and tablets charge completely pretty fast. But leaving their charging cords in outlets after you are done costs you money. Like a slow drip of a faucet, leaving charging cords in outlets can waste trace amounts of electricity when plugged in. Make sure you unplug when finished charging."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the cell phone and tablet. Recharging phones and tablets completely is pretty fast. But leaving their charging cords in outlets after you are done costs you money. Like a slow drip of a faucet, leaving charging cords in outlets can waste trace amounts of electricity when plugged in. Make sure you unplug when finished charging."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 4,
                    "text" : [
                        "Electricity has come a long way since the days of Thomas Edison.",
                        "Find the simplest desktop device that you can make a change with to cut energy costs."
                    ],
                    "hint" : "Can't see the answer? Turn the light on.",
                    "device": [4],
                    "answer_pool":[9,6,4,1,7,10],
                    "correct":[
                        "Alright!",
                        "LED or CFL bulbs used in desk lamps not only cost much less to use, but they give off much less heat and last years longer than incandescent bulbs. ComEd's Smart Ideas® Energy Efficiency Program provides instant in-store discounts on select ENERGY STAR® certified CFLs and LEDs at participating locations."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the desk lamp. LED and CFL bulbs save energy, give off much less heat and last years longer than incandescent bulbs. ComEd's Smart Ideas® Energy Efficiency Program provides instant in-store discounts on select ENERGY STAR® certified CFLs and LEDs at participating locations."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 5,
                    "text" : [
                        "This dynamic duo is getting smarter all of the time. Add some more savings by using cold water."
                    ],
                    "hint" : "Have you seen your favorite shirt?  It's not in your closet.",
                    "device": [5,6],
                    "answer_pool":[6,10,5,1,9,11],
                    "correct":[
                        "Yes.",
                        "Clothes washers and dryers make our lives easy. But using hot water can cost you. Consider using cold water to wash your clothes. Cleaning up on your laundry habits can help save you money and resources."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the clothes washer and dryer. Washers and dryers make our lives easy. But using hot water can cost you. Consider using cold water to wash your clothes.  Cleaning up on your laundry habits can help save you money and resources."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 6,
                    "text" : [
                        "Cleaning is my game.",
                        "I'm getting so smart that you can tell me when to run right from your phone."
                    ],
                    "hint" : "Whose turn is it to do the dishes?",
                    "device": [7],
                    "answer_pool":[2,6,1,9,10,7],
                    "correct":[
                        "Yes.",
                        "Appliances are getting smarter all the time, just like the electrical grid. Dishwashers are just one smart appliance that you can program automatically from your phone. Apps can talk to appliances and can tell you how much energy is being used."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the dishwasher. Appliances are getting smarter all the time, just like the electrical grid. Dishwashers are just one smart appliance that you can program automatically from your phone. Apps can talk to appliances and can tell you how much energy is being used."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 7,
                    "text" : [
                        "Letting me run all the time does not compute."
                    ],
                    "hint" : "I can surf the web with a mouse!",
                    "device": [9],
                    "answer_pool":[10,11,1,6,9,5],
                    "correct":[
                        "Yes!",
                        "Computers are something we often leave plugged in, even when they are powered off. But using a power strip to turn a computer off can ensure it is not still using electricity."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct answer is the computer. Computers are something we often leave plugged in, even when they are powered off. But using a power strip to turn a computer off can ensure it is not still using electricity."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 8,
                    "text" : [
                        "Wow, are these getting smart!  Everyone feels nice and comfy when I am set correctly."
                    ],
                    "hint" : "Watch the weather to tweak my settings.",
                    "device": [10],
                    "answer_pool":[7,11,1,10,5,6],
                    "correct":[
                        "Yes!",
                        "These thermostats are becoming 'smart,' like the Nest, and can help save heating and cooling energy automatically. When you're away, set your thermostat to adjust the temperature 10 degrees higher during the summer and 10 degrees lower during the winter."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct answer is the smart thermostat. Thermostats are becoming 'smart,' like the Nest, and can help save heating and cooling energy automatically. When you're away, set your thermostat to adjust the temperature 10 degrees higher during the summer and 10 degrees lower during the winter."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 9,
                    "text" : [
                        "Which device allows you to control what uses the most energy in this typical home?"
                    ],
                    "hint" : "How warm or cold are you?",
                    "device": [10],
                    "answer_pool":[7,1,10,5,6,2],
                    "correct":[
                        "Yes!",
                        "The smart thermostat, like the Nest, controls the furnace and the central air conditioner to make the temperature in your home comfortable. Even though you can program the thermostat to raise or lower the temperature when you are not at home and save energy, a lot of energy is still used to heat and cool a typical home."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the smart thermostat, like the Nest. The thermostat controls the furnace and the central air conditioner to make the temperature in your home comfortable. Even though you can program the thermostat to raise or lower the temperature when you are not at home and save energy, a lot of energy is still used to heat and cool a typical home."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 10,
                    "text" : [
                        "What uses the LEAST energy in this typical home?"
                    ],
                    "hint" : "When the batteries are low on these devices, you need to charge them up!",
                    "device": [3],
                    "answer_pool":[4,9,2,1,3,0],
                    "correct":[
                        "Correct.",
                        "The cell phone and tablet chargers."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct choice is the cell phone and tablet chargers."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 11,
                    "text" : [
                        "On average, which costs the most to use each month, the game console, the dishwasher, or the desk lamp?"
                    ],
                    "hint" : "The correct choice uses controllers.",
                    "device": [2],
                    "answer_pool":[2,7,4],
                    "correct":[
                        "Yes.",
                        "The game console. Of course it depends on how much the game console is used, but the average monthly cost to use a game console is about $2.77, a dishwasher costs about $1.73, and a desk lamp, depending on the bulb costs anywhere from $0.17 to $0.95 a month."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The answer is the game console. Of course it depends on how much the game console is used, but the average monthly cost to use a game console is about $2.77, a dishwasher costs about $1.73, and a desk lamp, depending on the bulb costs anywhere from $0.17 to $0.95 a month."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 12,
                    "text" : [
                        "Find the device you think can talk to your smart meter directly and tell you how much energy is being used."
                    ],
                    "hint" : "A computer is nice, but this device is built for the job.",
                    "device": [11],
                    "answer_pool":[1,11,7,6,2,5],
                    "correct":[
                        "Right!",
                        "The EMU (Energy Monitoring Unit) is one of many devices that can be connected to talk with your home's smart meter to let you know your energy use in real-time!"
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The correct answer is the EMU (Energy Monitoring Unit). It is one of many devices that can be connected to talk with your home's smart meter to let you know your energy use in real-time!"
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                },
                {
                    "number" : 13,
                    "text" : [
                        "Which appliance uses MORE energy, the clothes washer or the dryer?"
                    ],
                    "hint" : "The sun and wind can do this appliance's job.",
                    "device": [6],
                    "answer_pool":[5,6],
                    "correct":[
                        "Right!",
                        "The clothes dryer uses more energy than the washer. It costs, on average, $7.60 each month to use the dryer and $0.71 each month to use the washer."
                    ],
                    "incorrect":[
                        "Oops!", 
                        "The clothes dryer is the answer. It costs, on average, $7.60 each month to use the dryer and $0.71 each month to use the washer."
                    ],
                    "question_audio":"",
                "hint_audio":"",
                "correct_audio":"",
                "incorrect_audio":""
                }
            ]

        }
    ]
    
}

const deviceData = [
    {name: "Light Switch", img: 'lightswitch.png'},          
    {name: "Refrigerator", img: 'fridge.png'},          
    {name: "Game Console", img: 'xbox.png'},          
    {name: "Cell Phone & Tablet", img: 'tablet.png'},   
    {name: "Desk Lamp", img: 'desklamp.png'},             
    {name: "Washer", img: 'washer.png'},                
    {name: "Dryer", img: 'dryer.png'},                 
    {name: "Dishwasher", img: 'dishwasher.png'},            
    {name: "Electric Keyboard", img: 'keyboard.png'},     
    {name: "Computer", img: 'pc.png'},              
    {name: "Smart Thermostat", img: 'nest.png'},      
    {name: "Energy Monitoring Unit", img: 'han.png'},
]

export { quizQuestions, deviceData }