<template>
    <div class="deviceItem--container">
        <img :src="require(`../assets/images/device/${propToImg}`)" :alt="deviceName" >
        <h2>{{ deviceName }}</h2>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DeviceItem',
    data() {
        return {
            propToImg: this.deviceImg 
        }
    },
    props: {
        deviceName: String,
        deviceImg: String
    },
    mounted() { },
    methods: { }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .deviceItem--container {
        width: 100%;
        height: 100%;

        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;

        width: 13rem;
        height: 13rem;

        /* width: 15rem; */

        border-radius: 100%;

        padding: 3rem;

        background: var(--Pantone2745__dark-blue);

        text-align: center;
    }

    img {
        width: 50%;
        margin: 0 auto;
    }

    h2 {
        margin-top: 1rem;
        font-weight: 600;
        color: #ffffff; 
        font-size: var(--font-size-two)
    }
  </style>
  