<template>
    <div class="thinking--container">
        <h1>You picked {{ selectedDeviceName }}!</h1>
        <h3>Thinking...</h3>
        <img src="../assets/images/UI/icons/LightBulb.png" alt="thinking icon" />
    </div>
</template>
  
  <script>
  export default {
    name: 'ThinkingPage',
    data() {
      return {}
    },
    props: {
        selectedDeviceName: String
    },
    components: {}
  }
  </script>
  
  <style scoped>
.thinking--container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--Pantone2745__dark-blue);
    color: #fff;
}

h1 {
  font-size: 3.221rem;
  font-weight: 600;
  margin: 0 0 2rem 0;
  font-family: "prototype";
}

h3 {
  font-size: 2.221rem;
  font-weight: 600;
  margin: 0 0 2rem 0;
  font-family: "prototype";
}

img {
  max-width: 8rem;
}
  </style>
  