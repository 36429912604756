import { reactive } from 'vue'

export const store = reactive({
  grade: 'k-3', // grade settings options: k-3, 4-8, 9+

  // UI State
  showGradeSelection: true,
  showQuiz: false,
  quizOver: false,
  hideHomeButton: false,
})