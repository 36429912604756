import { store } from '../store.js';

var currentAudio = false;

export function startAudio(location){
    if(store.grade == "k-3")
    {
        let vo = new Audio(require(`../assets/audio/VO/${location}`));
        currentAudio = vo;
        currentAudio.load();
        currentAudio.play();
    }
}
export function stopAudio(){
    if (currentAudio)
   { 
        currentAudio.pause();
        currentAudio.currentTime = 0;
   }
}