<template>
    <div class="main--container">

        <!-- PAGE 1 -->
        <div class="welcome--container" v-if="showWelcome" @click="changePage(0)">
            
            <h2>WELCOME TO THE ENERGY MANAGEMENT CHALLENGE</h2>

            <div class="grid-1x2--container">
                <div class="left--container">
                    <p>
                        Work with your team to answer the questions that will 
                        appear on this screen about electricity usage. Explore 
                        the home to find items that answer each question.
                    </p>
                
                    <p>
                        To answer, have a team member push the button matching the
                        item or items located in the home.
                    </p>
                </div>

                <div class="right--container">
                    <img class="press--button" src="../assets/images/UI/icons/pressButton.png" />
                </div>
            </div>


        </div>

        <!-- PAGE 2 -->
        <div class="reminder--container" v-if="showReminder">

            <h2>Reminders</h2>

            <div class="grid-2x3--container">
                <div class="grid-2x3--item">
                    <img src="../assets/images/UI/icons/DontRun.png" />
                </div>
                <div class="grid-2x3--item">
                    <img src="../assets/images/UI/icons/buddies.png" />
                </div>

                <div class="grid-2x3--item">
                    <img src="../assets/images/UI/icons/HintButtonUP.png" />
                </div>
                <div class="grid-2x3--item">
                    <p>Please, do not run.</p>
                </div>

                <div class="grid-2x3--item">
                    <p>Work together as a team before you have one person make a final choice to answer the question.</p>
                </div>
                <div class="grid-2x3--item">
                    <p>Touch the "hint" button below a question if you need a little help.</p>
                </div>
            </div>

            <button class="begin--button" @click = "changePage(1)">begin</button>

        </div>

        <!-- PAGE 3 -->
        <div class="remember--container" v-if="showRemember" @click="goToQuiz()">
            
            <img src="../assets/images/UI/icons/biggerBuddies.png" />
            
            <p><strong>Remember!</strong> Work together and have a team member push the button matching the item or items to answer the question.</p>
        </div>   

    </div>
  </template>
  
  <script>
  import { store } from '../store.js'
  
  import {startAudio, stopAudio} from "../data/audio.js"

  export default {
    name: 'GradeSelection',
    data() {
        return {
            store,
            showWelcome:true,
            showRemember:false,
            showReminder:false,
            startAudio: startAudio,
            stopAudio: stopAudio
        }
    },
    methods: {
        changePage(number){
            //if number is one, it will be true
            if(number)
            {
                //change displayed
                this.showReminder = false;
                this.showRemember = true;
                //change vo
                this.stopAudio();
                this.startAudio("St 4-Screen 4.wav");

            }
            else //number must be 0
            {
                //change displayed
                this.showWelcome = false;
                this.showReminder = true;
                //change vo
                this.stopAudio();
                this.startAudio("St 4-Screen 3.wav");
            }
        },
        goToQuiz(){
            this.store.showIntro = false;
            this.store.showQuiz = true;
        }
    },
    mounted(){
        this.stopAudio();
        this.startAudio("St 4-Screen 2.wav");
    }
  }
  </script>

<style scoped>

    .main--container {
        padding: 0 8rem;
    }

    h2 {
        font-size: var(--font-size-five);
        font-weight: 600;
        margin-bottom: 2rem;
    }

    p {
        font-size: var(--font-size-three);
        font-weight: 600;
    }


    /* PAGE 1 */
    .welcome--container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--Pantone2745__dark-blue);
    }

    .welcome--container h2 {
        font-weight: 600;
        font-size: var(--font-size-five);
        font-family: "prototype";
        margin-bottom: 5rem;
    }

    .grid-1x2--container {
        display: grid;
        grid-template-columns: 60% 40%;
    }

    .left--container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: left;
    }

    .left--container p {
        margin-bottom: 2rem;
        /* font-family: "opensans"; */
        font-weight: 600;
    }

    .right--container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .right--container img {
        width: 90%;
    }

    /* PAGE 1 */

    /* PAGE 2 */

    .reminder--container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--Pantone2745__dark-blue);
    }

    .grid-2x3--container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 2rem;
    }

    .grid-2x3--item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .grid-2x3--item img {
        width: 60%;
    }

    .grid-2x3--item p {
        text-align: left;
        font-size: var(--font-size-two);
        font-weight: 600;
        /* font-family: "opensans" */
    }

    .reminder--container h2 {
        font-size: var(--font-size-six);
        text-transform: uppercase;
        margin-bottom: 4rem;
        font-family: "prototype"
    }

    .reminder--container button {
        margin-top: 2rem;
    }

    /* PAGE 2 */

    /* PAGE 3 */
    .remember--container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--Pantone2745__dark-blue);
    }

    .remember--container img {
        width: 30%;
        margin-bottom: 4rem;
    }

    .remember--container p {
        font-size: var(--font-size-four);
        font-weight: 600;
    }

    /* PAGE 3 */
</style>