<template>
      
  <div class="main--container">
    
    <!-- landing page -->
    <div class="inner--container" v-if="store.showGradeSelection">
      <div class="title--container">
        <div class="title--big"> 
          ENERGY
        </div>
        <div class="title--small"> 
          MANAGEMENT
        </div>
        <div class="title--big"> 
          AT HOME
        </div>
      </div>

      <GradeSelection v-if="store.showGradeSelection" />
      <button v-if="store.showGradeSelection" @click="startApp">START</button>
    </div>
      
    <!-- instructions -->
    <IntroPage v-if="store.showIntro" />

    <!-- quiz -->
    <Quiz v-if="store.showQuiz" />

    <!-- quiz completed page -->
    <EndPage v-if="store.quizOver" />

    <!-- update app -->
    <div class="update--container" v-if="updateExists">
      <h2>New update available!</h2>
      <button @click="refreshApp">
        UPDATE
      </button>
    </div>

    <!-- return to home link -->
    <div 
      class="home-button--container" 
      v-if="store.showGradeSelection === false"
      @click="returnHome"
    >
      <button v-if="(store.hideHomeButton === false)">
        <img src="./assets/images/UI/icons/home-blue.png" />
      </button>
    </div> 

    <!-- v-if="store.hideHomeButton == true" -->

  </div>
</template>

<script>
import './style/resets.css'
import './style/var.css'
import './style/animations.css'

import { store } from './store.js'

import GradeSelection from './components/GradeSelection.vue'
import Quiz from './components/Quiz.vue'
import IntroPage from './components/IntroPage.vue'
import EndPage from './components/EndPage.vue'

export default {
  name: 'App',
  data() {
    return {
      store,
      registration: null,
      updateExists: false,
      refreshing: false
    }
  },
  components: {
    GradeSelection,
    Quiz,
    IntroPage,
    EndPage
  },

  created () {
    document.addEventListener(
      'swUpdated', this.updateAvailable, { once: true }
    );  
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
      window.location.reload()
    })

  },

  methods: {
    startApp() {
      store.showGradeSelection = false

      // show instruction
      store.showIntro = true
      store.showQuiz = false
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    returnHome() {
      window.location.reload();
    }

  }
}
</script>

<style>
html {
  overflow: hidden;
  touch-action: pan-y pan-x; /*prevent user scaling*/
  touch-action: manipulation; /* disabled double tap to zoom on mobile */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  margin: 0;

  background-image: url("./assets/images/UI/backgrounds/WhiteWaveBackground.jpg");
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover; 

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.main--container {
  width: 100vw;
  height: 100vh;
  max-width: 1920px;
  max-height: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.inner--container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

button {
  background: var(--Pantone2745__dark-blue--light);
  border: 3px solid var(--Pantone2745__dark-blue);
  font-size: var(--font-size-three);
  font-weight: 600;
  color: #fff;
  padding: 0.75rem;
}

.title--container {
  margin-bottom: 2rem;
  font-family: "neversaydie";
}

.title--small {
  font-size: 2.721rem;
  letter-spacing: 0.6rem;
  color: var(--Pantone2745__dark-blue--light);
  font-weight: 900;
  font-style: italic;
  line-height: 4rem;
}

.title--big {
  font-size: 5.102rem;
  color: var(--Pantone2745__dark-blue--light);
  font-weight: 900;
  font-style: italic;
  line-height: 5rem;
  letter-spacing: 0.4rem;
}

.update--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.95);
}

.update--container h2 {
  margin-bottom: 2rem;
  font-size: 2.721rem;
  font-family: "prototype"
}

.update--container button {
  background: none;
  border-color: #fff;
}

.home-button--container {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 4rem;
  z-index: 1
}

.home-button--container button {
  background: none;
  border: none;
}
</style>
