<template>
    <div class="incorrect--container">
        <!-- CORRECT -->
        <div class="grid-left">
          <h1>{{ incorrectText.incorrect[0] }}</h1>
          <img :src="require(`../assets/images/device/${deviceImg}`)" :alt="deviceName" />
          <h2>{{ deviceName }}</h2>
        </div>

        <div class="grid-right">
          <h3>{{ incorrectText.incorrect[1] }}</h3>
        </div>
    </div>
</template>
  
  <script>
  
  export default {
    name: 'IncorrectPage',
    data() {
      return {
        
      }
    },
    props: {
      incorrectText: Object,
      deviceName: String,
      deviceImg: String
    },
    components: {},
    mounted() {

    },
  }
  </script>
  
  <style scoped>
.incorrect--container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: #fff;
  color: var(--Pantone2745__dark-blue);
  background-image: url("../assets/images/UI/backgrounds/WhiteWaveBackground.jpg");
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover; 
  padding: 2rem;
}

.grid-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.grid-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

img {
  width: 10rem;
  margin: 3rem 0;
}

h1 {
  font-size: var(--font-size-six);
  font-weight: 600;
  text-transform: uppercase;
  font-family: "prototype"
}

h2 {
  font-size: var(--font-size-five);
  font-weight: 600;
  font-family: "prototype"
}

h3 {
  font-size: 1.753rem;
  font-weight: 600;
}
  </style>
  