<template>
    <div class="gradeSelection--container">
      <h2>Select your grade to begin</h2>

      <div class="radio-list">
        <input type="radio" v-model="store.grade" value="k-3">
        <h3>K-3</h3>
        <input type="radio" v-model="store.grade" value="4-8">
        <h3>4-8</h3>
        <input type="radio" v-model="store.grade" value="9+">
        <h3>9+</h3>
      </div>
    </div>
  </template>
  
  <script>
  import { store } from '../store.js'

  export default {
    name: 'GradeSelection',
    data() {
        return {
            store
        }
    },
    props: {
      msg: String
    },
    methods: {}
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .gradeSelection--container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: var(--Pantone2745__dark-blue);

        border-top: 6px solid;
        border-bottom: 6px solid;
        padding: 2rem;
        margin-bottom: 2rem;
        font-family: "prototype";
    }

  .radio-list {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  h2 {
    margin-right: 1rem;
    font-size: var(--font-size-four);
    font-weight: 600;
  }

  input {
    margin-right: 1rem;
    accent-color: var(--Pantone2745__dark-blue);
  }

  h3 {
    font-size: var(--font-size-four);
    font-weight: 600;
  }
  </style>
  