<template>
    <div class="quiz--container swipe-animation--in">
        <div class="top--container">
            <!-- find me -->
            <h1>FIND ME!</h1>

            <!-- questions -->
            <div class="question--container">
                <h2 v-for="question in currentQuestion.text" v-bind:key="question">
                    {{ question }}
                </h2>
            </div>
        </div>

        <!-- answer pool -->
        <div class="answer-grid">
            <DeviceItem 
                v-for="answer in currentQuestion.answer_pool" 
                v-bind:key="answer"

                :deviceName="device[answer].name" 
                :deviceImg="device[answer].img" 

                @click="selectAnswer(answer, device[answer].name)"
            />
        </div>

        <!-- hint -->
        <div class="hint--container">
            <button @click="toggleHint">hint</button>
            <h3 v-if="showHint">{{ currentHint }}</h3>
        </div>

        <!-- transition animation -->
        <ThinkingPage 
            v-if="showThinking"
            :selectedDeviceName="selectedDeviceName"
            :class="[thinkingAddSwipeInClass, thinkingAddSwipeOutClass]"
        />

        <!-- correct -->
        <CorrectPage
            v-if="showCorrect"
            @click="nextQuestion('correct')"

            :correctText="currentQuestion"
            :deviceName="device[answer].name"
            :deviceImg="device[answer].img"
        /> 

        <!-- incorrect -->
        <IncorrectPage 
            v-if="showIncorrect" 
            @click="nextQuestion('incorrect')"

            :incorrectText="currentQuestion"
            :deviceName="device[currentQuestion.device[0]].name"
            :deviceImg="device[currentQuestion.device[0]].img"
        />
        
    </div>
  </template>
  
  <script>
  import { store } from '../store.js'
  import { quizQuestions, deviceData } from '../data/data.js'
  import randomizeList from "../data/randomize.js"
  import {startAudio, stopAudio} from "../data/audio.js"
  import DeviceItem from './DeviceItem.vue'
  import ThinkingPage from './ThinkingPage.vue'
  import CorrectPage from './CorrectPage.vue'
  import IncorrectPage from './IncorrectPage.vue'
 

  export default {
    name: 'Quiz',
    components: {
        DeviceItem,
        ThinkingPage,
        CorrectPage,
        IncorrectPage,
      
    },
    data() {
        return {
            store,
            device: deviceData,
            quiz: quizQuestions,

            currentQuestionGroup: [],
            currentQuestion: [],
            currentQuestionIndex: 0,
            currentHint: '',
            selectedDeviceName: '',

            totalQuestions: 0,
            totalQuestionsAnswered: 0,

            // state
            showThinking: false,
            showHint: false,
            thinkingSwipeOut: false,

            showCorrect: false,
            showIncorrect: false,
            
            quizOver: false,

            thinkingAddSwipeIn: false,
            thinkingAddSwipeOut: false,

            answer: '',

            startAudio: startAudio,
            stopAudio: stopAudio
        }
    },
    computed: {
        thinkingAddSwipeInClass() {
            return this.thinkingAddSwipeIn ? 'swipe-animation--in' : ''
        },
        thinkingAddSwipeOutClass() {
            return this.thinkingAddSwipeOut ? 'swipe-animation--out' : ''
        },
    },
    props: { },
    mounted() {
        // set quiz difficulty based on selected grade level
        this.setQuizGroup()

        // find 
        this.setCurrentQuestion(this.currentQuestionIndex)
    },
    methods: { 
        setQuizGroup() {
            switch(this.store.grade) {
                case 'k-3':
                    this.currentQuestionGroup = randomizeList(quizQuestions.group[0].one)
                    break;
                case '4-8':
                    this.currentQuestionGroup = randomizeList(quizQuestions.group[1].two)
                    break;
                case '9+':
                    this.currentQuestionGroup = randomizeList(quizQuestions.group[2].three)
                    break
                default:
                
            }

            (this.currentQuestionGroup.length)
        },
        setCurrentQuestion(question) {
            this.currentQuestion = this.currentQuestionGroup[question]

            // set current hint
            this.currentHint = this.currentQuestionGroup[question].hint;
            this.stopAudio();
            this.startAudio(this.currentQuestion.question_audio);
        },
        selectAnswer(thisDevice, thisDeviceName) {
            this.answer = thisDevice;

            // show thinking.. transition
            this.toggleThinkingTransition(thisDeviceName)

            this.stopAudio();
            
            // wait for thinking page to tranistion into view before updating to next answer pool
            setTimeout(() => {
                if (thisDevice === this.currentQuestion.device[0]) {
            
                    // handle correct
                    this.showCorrect = true
                                      
                
                } else {
                    // handle incorrect
                    this.showIncorrect = true
                }
            }, 1000)
            
        },
        toggleHint() {
            this.showHint = !this.showHint
            if(this.showHint)
            {
                this.stopAudio();
                this.startAudio(this.currentQuestion.hint_audio);
            }
        },
        toggleThinkingTransition(thisDeviceName) {
            // store name of selected device to display it on thinking.. transsition page
            this.selectedDeviceName = thisDeviceName

            // displays thinking transition when true
            this.thinkingAddSwipeIn = true
            this.showThinking = true

            
            setTimeout(() => {
                // remove swipe in class
                this.thinkingAddSwipeIn = false

                // add swipe out class
                this.thinkingAddSwipeOut = true

                setTimeout(() => {
                    // stop showing transition page
                    this.showThinking = false

                    // remove swipe out class
                    this.thinkingAddSwipeOut = false
                }, 480)
                
                //stop audio and start playing correct or incorrect answer audio after transition
                this.stopAudio();

                if (this.showCorrect)
                {
                    this.startAudio(this.currentQuestion.correct_audio);
                }
                
                else
                {
                    this.startAudio(this.currentQuestion.incorrect_audio);
                }

            }, 2000) // 500 
        },
        nextQuestion(result) {
            // if there are more questions
            if (this.currentQuestionIndex < this.currentQuestionGroup.length - 1) {

                // increment to next question
                this.currentQuestionIndex++
                this.setCurrentQuestion(this.currentQuestionIndex)

                // if hint is shown, hide before showing next question
                if (this.showHint) {
                    this.showHint = false
                }

            } else {
                // all questions answered
                this.stopAudio();
                this.store.showQuiz = false;
                this.store.quizOver = true;
                this.store.hideHomeButton = true
            }

            // hide result page
            if (result === 'correct') {
                this.showCorrect = false;
            } else if (result === 'incorrect') {
                this.showIncorrect = false;
            }
        }
     }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      .quiz--container {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        align-content: center;
        justify-content:space-around;
        flex-direction: column;  
        color: var(--Pantone2745__dark-blue);
        text-align: left;
    }

    .answer-grid {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        place-items: center;
        margin-bottom: 2rem;
    }

    h1 {
        font-size: var(--font-size-six);
        font-weight: 900;
        margin-left: 2rem;
        font-family: "prototype"
    }

    .question--container {
        margin: 0 2rem;
    }

    .question--container h2 {
        font-size: var(--font-size-four);
        font-weight: 600;
    }

    .hint--container {
        display: flex;
        align-items: center;
        margin-left: 2rem;
    }

    .hint--container button {
        background-color: var(--button-color);
        font-size: var(--font-size-four);
        color: #fff;
        outline: none;
        border: none;
        padding: 1rem 1.5rem;
        border-radius: 10px;
        font-weight: 600;
        margin-right: 2rem;
    }

    .hint--container h3 {
        font-size: var(--font-size-three);
        color: var(--Pantone2745__dark-blue);
        font-weight: 600;
    }
  </style>
  