function randomizeList (list) {
    let len = list.length;
    for (let i=0; i< len; i++)
    {
        let randomIndex = Math.floor(Math.random()* len);
        let swap = list[i];
        list[i] = list[randomIndex];
        list[randomIndex] = swap;
    }
    return list;
}

module.exports = randomizeList;